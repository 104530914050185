<template>
    <div>
        <tutti-dialog
            ref="dialog"
            max-width="800"
            name="mturk-worker-send-email"
            :buttons="buttons"
            :overlay="overlay"
            @open="onDialogOpen"
            @close="onDialogClose"
        >
            <template #activator="{ on, attrs }">
                <v-btn
                    text
                    v-on="on"
                    v-bind="attrs"
                    :disabled="checkedItems.length===0"
                    color="indigo"
                >
                    <v-icon left v-text="buttonIcon" />
                    {{ buttonLabel }}
                </v-btn>
            </template>
            <template #overlay>
                <slot name="overlay" />
            </template>
            <template #title>
                <slot name="title" />
            </template>
            <template #body>
                <v-card-text v-if="!progress || !progress.shown">
                    <v-combobox
                        dense
                        multiple
                        small-chips
                        outlined
                        hide-selected
                        v-model="selectedItems"
                        :items="items"
                        :item-text="itemText"
                        :item-value="itemValue"
                        :label="comboboxLabel"
                        :search-input.sync="itemSearchQuery">
                        <template v-slot:no-data>
                            <v-list-item>
                                <v-list-item-content>
                                    <v-list-item-title>
                                        No results matching "<strong>{{ itemSearchQuery }}</strong>". Press <kbd>enter</kbd> to create a new one
                                    </v-list-item-title>
                                </v-list-item-content>
                            </v-list-item>
                        </template>
                    </v-combobox>
                    <slot name="body" />
                </v-card-text>
                <v-card-text v-else>
                    <div class="pa-10 text-center">
                        <v-progress-linear striped :value="progress.percentage" height="20" color="indigo lighten-3">
                            <template v-slot:default="{ value }">
                                <strong>{{ parseInt(value) }}% ({{ progress.counts.success+progress.counts.error }}/{{ progress.counts.all }})</strong>
                            </template>
                        </v-progress-linear>
                        <div class="pt-5 black--text" v-if="progress.percentage===100">
                            Process completed!<br>
                            (Success: {{ progress.counts.success }}, Error: {{ progress.counts.error }})<br>
                            <div v-if="progress.errors.length" class="my-6 text-left">
                                Error logs:<br>
                                <div style="border:solid 1px #ccc">
                                    <v-virtual-scroll item-height="20" height="100" style="font-size:10pt;" :items="progress.errors">
                                        <template #default="{ index, item }">
                                            <div class="d-flex">
                                                <div style="width:30px;">[{{ index+1 }}]</div>
                                                <div class="text-no-wrap" style="width:0;">{{ item }}</div>
                                            </div>
                                        </template>
                                    </v-virtual-scroll>
                                </div>
                            </div>
                            <v-btn dark color="indigo" @click="onComplete()">Close</v-btn>
                        </div>
                    </div>
                </v-card-text>
            </template>
        </tutti-dialog>
        <tutti-dialog-alert ref="alert" @confirm="onConfirm">
            <template #body>
                <slot name="alert-body" :selectedItems="selectedItems" />
            </template>
        </tutti-dialog-alert>
    </div>
</template>
<script>
import TuttiDialog from '@/components/ui/TuttiDialog'
import TuttiDialogAlert from '@/components/ui/TuttiDialogAlert'

export default {
    components: {
        TuttiDialog,
        TuttiDialogAlert
    },
    data: () => ({
        itemSearchQuery: "",
        selectedItems: [],
    }),
    props: {
        items: { type: Array, default: () => ([]) },
        checkedItems: { type: Array, default: () => ([]) },
        itemText: { type: String, default: 'text' },
        itemValue: { type: String, default: 'value' },
        comboboxLabel: {type: String, default: '' },

        buttonLabel: { type: String, default: '' },
        buttonIcon: { type: String, default: '' },
        canConfirm: { type: Boolean, default: true },
        progress: { type: Object, default: null },
        overlay: { type: Object, default: null },
        showConfirmAlert: { type: Boolean, default: true },
    },
    computed: {
        buttons() {
            return this.progress && this.progress.shown
                ? []
                : [
                    {
                        label: this.$t('console.platforms.mturk.combobox.buttons.cancel'),
                        on: { click: () => { this.hide(); } },
                        attrs: { text: true, color: 'grey darken-2' }
                    },
                    {
                        label: this.$t('console.platforms.mturk.combobox.buttons.proceed'),
                        on: { click: () => { this.showConfirmAlert ? this.onProceed() : this.onConfirm();  } },
                        attrs: { text: true, color: 'indigo darken-2', disabled: !this.canConfirm }
                    },
                ];
        }
    },
    methods: {
        onDialogOpen() {
            this.selectedItems = this.checkedItems;
            this.$emit('dialog-open');
        },
        onDialogClose() {
            this.$emit('dialog-close');
        },
        onProceed() {
            this.$refs.alert.show();
        },
        onConfirm() {
            this.$emit('confirm', this.selectedItems);
        },
        onComplete() {
            this.$emit('complete');
            this.hide();
        },
        show() { this.$refs.dialog.show(); },
        hide() { this.$refs.dialog.hide(); }
    }
}
</script>
