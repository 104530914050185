var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _c("tutti-dialog", {
        ref: "dialog",
        attrs: {
          "max-width": "800",
          name: "mturk-worker-send-email",
          buttons: _vm.buttons,
          overlay: _vm.overlay,
        },
        on: { open: _vm.onDialogOpen, close: _vm.onDialogClose },
        scopedSlots: _vm._u(
          [
            {
              key: "activator",
              fn: function ({ on, attrs }) {
                return [
                  _c(
                    "v-btn",
                    _vm._g(
                      _vm._b(
                        {
                          attrs: {
                            text: "",
                            disabled: _vm.checkedItems.length === 0,
                            color: "indigo",
                          },
                        },
                        "v-btn",
                        attrs,
                        false
                      ),
                      on
                    ),
                    [
                      _c("v-icon", {
                        attrs: { left: "" },
                        domProps: { textContent: _vm._s(_vm.buttonIcon) },
                      }),
                      _vm._v(" " + _vm._s(_vm.buttonLabel) + " "),
                    ],
                    1
                  ),
                ]
              },
            },
            {
              key: "overlay",
              fn: function () {
                return [_vm._t("overlay")]
              },
              proxy: true,
            },
            {
              key: "title",
              fn: function () {
                return [_vm._t("title")]
              },
              proxy: true,
            },
            {
              key: "body",
              fn: function () {
                return [
                  !_vm.progress || !_vm.progress.shown
                    ? _c(
                        "v-card-text",
                        [
                          _c("v-combobox", {
                            attrs: {
                              dense: "",
                              multiple: "",
                              "small-chips": "",
                              outlined: "",
                              "hide-selected": "",
                              items: _vm.items,
                              "item-text": _vm.itemText,
                              "item-value": _vm.itemValue,
                              label: _vm.comboboxLabel,
                              "search-input": _vm.itemSearchQuery,
                            },
                            on: {
                              "update:searchInput": function ($event) {
                                _vm.itemSearchQuery = $event
                              },
                              "update:search-input": function ($event) {
                                _vm.itemSearchQuery = $event
                              },
                            },
                            scopedSlots: _vm._u(
                              [
                                {
                                  key: "no-data",
                                  fn: function () {
                                    return [
                                      _c(
                                        "v-list-item",
                                        [
                                          _c(
                                            "v-list-item-content",
                                            [
                                              _c("v-list-item-title", [
                                                _vm._v(
                                                  ' No results matching "'
                                                ),
                                                _c("strong", [
                                                  _vm._v(
                                                    _vm._s(_vm.itemSearchQuery)
                                                  ),
                                                ]),
                                                _vm._v('". Press '),
                                                _c("kbd", [_vm._v("enter")]),
                                                _vm._v(" to create a new one "),
                                              ]),
                                            ],
                                            1
                                          ),
                                        ],
                                        1
                                      ),
                                    ]
                                  },
                                  proxy: true,
                                },
                              ],
                              null,
                              false,
                              3333601075
                            ),
                            model: {
                              value: _vm.selectedItems,
                              callback: function ($$v) {
                                _vm.selectedItems = $$v
                              },
                              expression: "selectedItems",
                            },
                          }),
                          _vm._t("body"),
                        ],
                        2
                      )
                    : _c("v-card-text", [
                        _c(
                          "div",
                          { staticClass: "pa-10 text-center" },
                          [
                            _c("v-progress-linear", {
                              attrs: {
                                striped: "",
                                value: _vm.progress.percentage,
                                height: "20",
                                color: "indigo lighten-3",
                              },
                              scopedSlots: _vm._u([
                                {
                                  key: "default",
                                  fn: function ({ value }) {
                                    return [
                                      _c("strong", [
                                        _vm._v(
                                          _vm._s(parseInt(value)) +
                                            "% (" +
                                            _vm._s(
                                              _vm.progress.counts.success +
                                                _vm.progress.counts.error
                                            ) +
                                            "/" +
                                            _vm._s(_vm.progress.counts.all) +
                                            ")"
                                        ),
                                      ]),
                                    ]
                                  },
                                },
                              ]),
                            }),
                            _vm.progress.percentage === 100
                              ? _c(
                                  "div",
                                  { staticClass: "pt-5 black--text" },
                                  [
                                    _vm._v(" Process completed!"),
                                    _c("br"),
                                    _vm._v(
                                      " (Success: " +
                                        _vm._s(_vm.progress.counts.success) +
                                        ", Error: " +
                                        _vm._s(_vm.progress.counts.error) +
                                        ")"
                                    ),
                                    _c("br"),
                                    _vm.progress.errors.length
                                      ? _c(
                                          "div",
                                          { staticClass: "my-6 text-left" },
                                          [
                                            _vm._v(" Error logs:"),
                                            _c("br"),
                                            _c(
                                              "div",
                                              {
                                                staticStyle: {
                                                  border: "solid 1px #ccc",
                                                },
                                              },
                                              [
                                                _c("v-virtual-scroll", {
                                                  staticStyle: {
                                                    "font-size": "10pt",
                                                  },
                                                  attrs: {
                                                    "item-height": "20",
                                                    height: "100",
                                                    items: _vm.progress.errors,
                                                  },
                                                  scopedSlots: _vm._u(
                                                    [
                                                      {
                                                        key: "default",
                                                        fn: function ({
                                                          index,
                                                          item,
                                                        }) {
                                                          return [
                                                            _c(
                                                              "div",
                                                              {
                                                                staticClass:
                                                                  "d-flex",
                                                              },
                                                              [
                                                                _c(
                                                                  "div",
                                                                  {
                                                                    staticStyle:
                                                                      {
                                                                        width:
                                                                          "30px",
                                                                      },
                                                                  },
                                                                  [
                                                                    _vm._v(
                                                                      "[" +
                                                                        _vm._s(
                                                                          index +
                                                                            1
                                                                        ) +
                                                                        "]"
                                                                    ),
                                                                  ]
                                                                ),
                                                                _c(
                                                                  "div",
                                                                  {
                                                                    staticClass:
                                                                      "text-no-wrap",
                                                                    staticStyle:
                                                                      {
                                                                        width:
                                                                          "0",
                                                                      },
                                                                  },
                                                                  [
                                                                    _vm._v(
                                                                      _vm._s(
                                                                        item
                                                                      )
                                                                    ),
                                                                  ]
                                                                ),
                                                              ]
                                                            ),
                                                          ]
                                                        },
                                                      },
                                                    ],
                                                    null,
                                                    false,
                                                    2342542863
                                                  ),
                                                }),
                                              ],
                                              1
                                            ),
                                          ]
                                        )
                                      : _vm._e(),
                                    _c(
                                      "v-btn",
                                      {
                                        attrs: { dark: "", color: "indigo" },
                                        on: {
                                          click: function ($event) {
                                            return _vm.onComplete()
                                          },
                                        },
                                      },
                                      [_vm._v("Close")]
                                    ),
                                  ],
                                  1
                                )
                              : _vm._e(),
                          ],
                          1
                        ),
                      ]),
                ]
              },
              proxy: true,
            },
          ],
          null,
          true
        ),
      }),
      _c("tutti-dialog-alert", {
        ref: "alert",
        on: { confirm: _vm.onConfirm },
        scopedSlots: _vm._u(
          [
            {
              key: "body",
              fn: function () {
                return [
                  _vm._t("alert-body", null, {
                    selectedItems: _vm.selectedItems,
                  }),
                ]
              },
              proxy: true,
            },
          ],
          null,
          true
        ),
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }